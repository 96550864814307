import React from "react";
import Home from "../pages/home";
import Page from "@jumbo/shared/Page";

import Users from "../pages/users/Users";
import Reports from "../pages/reports";

import tournamentRoutes from "./tournamentRoutes";
import authRoutes from "./authRoutes";
import userRoutes from "./userRoutes";
import questionRoutes from "./questionRoutes";
import Ranking from "../pages/rankings/Ranking";
import ProtectedRoute from "./ProtectedRoute";

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [

];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
    {
        path: "/dashboard",
        element: <Page component={Home} />
    },
    {
        path: "/users",
       /*  element: <Page component={Users} /> */
        element: 
        <ProtectedRoute 
            requiredRole={'admin'}
            component={Users}
        />
    },
    {
        path: "/reports",
        element: <Page component={Reports} />
    },
    {
        path:"/rankings",
        element: <Page component={Ranking}/>
    },

   
    ...tournamentRoutes,
    ...userRoutes,
    ...questionRoutes,


];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
    ...authRoutes,

];


const routes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly,
];

export { routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly };