import { PermissionContext } from "app/contexts/PermissionContext";
import { Navigate, Route } from "react-router-dom";
import { useContext } from "react";
import Page from "@jumbo/shared/Page";

function ProtectedRoute({requiredRole, component }) {
    const { hasPermission } = useContext(PermissionContext);
   
    if (!hasPermission(requiredRole)) {
      return <Navigate to="/app" />;
    }
  
    return <Page component={component} />;
  }
  
  export default ProtectedRoute;
