import React, { useEffect } from 'react';
import { Stack, useMediaQuery } from "@mui/material";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import UserList from './components/UserList/UserList';
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import { alpha } from "@mui/material/styles";

import { UsersAppProvider } from './UsersAppProvider';
import { useLocation } from 'react-router-dom';
import CustomAlert from '../components/Alerts/CustomAlert';
import HeaderUsers from './components/HeaderUsers';

const Users = () => {
    const { theme } = useJumboTheme();
    const location = useLocation();
    const [openAlertSuccessUpdate, setOpenAlertSuccessUpdate] = React.useState(false);

    useEffect(() => {

        if (location.state && location.state.showToastUpdateUser) {
            // Exibir o toast de sucesso aqui
            setOpenAlertSuccessUpdate(true);
        }

    }, [location]);

    const handleCloseAlertSuccessUpdate = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlertSuccessUpdate(false);
    };

    return (
        <UsersAppProvider>
            <HeaderUsers />
            <JumboContentLayout
                
                layoutOptions={{
                    header: {
                        sx: {
                            mt: -4,
                            mb: -7.25,
                            mx: { xs: -4, lg: -6 },
                            p: { xs: theme.spacing(6, 4, 11), lg: theme.spacing(6, 6, 11) },
                            backgroundSize: 'cover',
                            color: 'common.white',
                            position: 'relative',

                            '&::after': {
                                display: 'inline-block',
                                position: 'absolute',
                                content: `''`,
                                inset: 0,

                            }
                        }
                    },
                    sidebar: {
                        sx: {
                            mr: 3.75,
                            width: { xs: '100%', lg: '33%' },
                            [theme.breakpoints.down('lg')]: {
                                minHeight: 0,
                                mr: 0,
                                order: 2
                            }
                        }
                    },
                    wrapper: {
                        sx: {
                            [theme.breakpoints.down('lg')]: {
                                flexDirection: 'column'
                            }
                        }
                    },
                    main: {
                        sx: {
                            [theme.breakpoints.down('lg')]: {
                                minHeight: 0
                            }
                        }
                    }
                }}
            >
                <UserList />

                <div>


                    <CustomAlert
                        open={openAlertSuccessUpdate}
                        onClose={handleCloseAlertSuccessUpdate}
                        severity="success"
                        message="Usuário editado com sucesso!"
                    />
                </div>
            </JumboContentLayout>
        </UsersAppProvider>


    );
};

export default Users;