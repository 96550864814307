import baseAxios from "axios";

const axios = baseAxios.create({
    baseURL: process.env.REACT_APP_API_KEY,
    timeout: 5000,
    withCredentials: true,
    credentials: 'include',
    headers: {
        'Content-Type': 'application/json'


    }
});
axios.interceptors.response.use(
    (response) => {


        return response;
    },
    (error) => {

        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            window.location.href = '/user/login';
        } else {
            // Outro erro, trate conforme necessário
            throw error;
        }
    }
);



//todo: define interceptors and other configuration like baseURL, headers etc. here
export default axios;