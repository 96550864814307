import React from 'react';

const Reports = () => {
    return (
        <div>
            <h2>Reports</h2>
            <p>This page is just to showcase the way you can add your own pages.</p>
            <p>Happy Coding!</p>
        </div>
    );
};

export default Reports;