import { useState, useEffect, useContext, createContext } from 'react'
import useJumboAuth from "@jumbo/hooks/useJumboAuth";

export const PermissionContext = createContext();


export const PermissionProvider = ({children}) => {
    const { authToken, authUser } = useJumboAuth();

    const hasPermission = (role) => {
      let hasPermission = false;

      if(authUser.role === role) {
        hasPermission = true;
      }
      return hasPermission;
    };
    
    return (
        <PermissionContext.Provider value={{ hasPermission }}>
          {children}
        </PermissionContext.Provider>
      );
}
