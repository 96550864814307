import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import { useJumboTheme } from '@jumbo/hooks';
import ContentHeader from 'app/layouts/shared/headers/ContentHeader';
import { questionService } from 'app/services/question-service';
import React, { useEffect, useState } from 'react'
import QuestionEditor from './QuestionEditor';
import { QuestionsAppProvider } from './QuestionsAppProvider';


const QuestionEdit = () => {
  const { theme } = useJumboTheme();

  const [expandedQuestions, setExpandedQuestions] = useState([]);
  const token = localStorage.getItem("token");


  const [questionData, setQuestionData] = useState(null); 


  useEffect(() => {
    const fetchData = async () => {
      try {
        const questionId = window.location.pathname.split('/').pop();

        const res = await questionService.getQuestion(token ,questionId)

        if (!res) {
          return;
        }

        if (res) {
          setQuestionData(res);

        } else {
          console.error("No questions in the list.");
        }
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchData();
  }, []);

  const toggleQuestion = (questionId) => {
    // Verificar se a pergunta está na lista de expandidas
    const isExpanded = expandedQuestions.includes(questionId);

    // Se estiver expandida, remova-a; caso contrário, adicione-a à lista
    if (isExpanded) {
      setExpandedQuestions(expandedQuestions.filter((id) => id !== questionId));
    } else {
      setExpandedQuestions([...expandedQuestions, questionId]);
    }
  };

  if (!questionData) {
    return <p>Carregando pergunta...</p>; // Página de carregamento enquanto os dados são buscados
  }
  return (
    <QuestionsAppProvider>
      <ContentHeader
        title={"Editar Questão"}

      />
      <JumboContentLayout
        layoutOptions={{
          header: {
            sx: {
              mt: -4,
              mb: -7.25,
              mx: { xs: -4, lg: -6 },
              p: { xs: theme.spacing(6, 4, 11), lg: theme.spacing(6, 6, 11) },
              backgroundSize: 'cover',
              color: 'common.white',
              position: 'relative',

              '&::after': {
                display: 'inline-block',
                position: 'absolute',
                content: `''`,
                inset: 0,

              }
            }
          },
          sidebar: {
            sx: {
              mr: 3.75,
              width: { xs: '100%', lg: '33%' },
              [theme.breakpoints.down('lg')]: {
                minHeight: 0,
                mr: 0,
                order: 2
              }
            }
          },
          wrapper: {
            sx: {
              [theme.breakpoints.down('lg')]: {
                flexDirection: 'column'
              }
            }
          },
          main: {
            sx: {
              [theme.breakpoints.down('lg')]: {
                minHeight: 0
              }
            }
          }
        }}
      >
        <QuestionEditor questionData={questionData} />
      </JumboContentLayout>
    </QuestionsAppProvider>


  );
};

export default QuestionEdit;