const FormatDate = (value) => {
  var dataObjeto = new Date(value);

  var ano = dataObjeto.getFullYear();
  var mes = ('0' + (dataObjeto.getMonth() + 1)).slice(-2);
  var dia = ('0' + dataObjeto.getDate()).slice(-2);
  var hora = ('0' + dataObjeto.getHours()).slice(-2);
  var minuto = ('0' + dataObjeto.getMinutes()).slice(-2);
  var segundo = ('0' + dataObjeto.getSeconds()).slice(-2);

  var dataFormatada = ano + '-' + mes + '-' + dia + ' ' + hora + ':' + minuto + ':' + segundo;

  return dataFormatada;

}

export default FormatDate;