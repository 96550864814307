import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Typography,
  Paper,
  Container,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { tournamentService } from 'app/services/tournament-service';
import CustomAlert from '../components/Alerts/CustomAlert';
import { useNavigate } from 'react-router-dom';
import FormCreateTournament from './components/Forms/FormCreateTournament';
import StageForm from './components/Forms/StageForm';
import QuestionForm from './components/Forms/QuestionForm';
import FormatDate from 'app/components/FormatDate/FormatDate';
import Div from '@jumbo/shared/Div';

function TournamentEditor({ tournamentDataOrigin }) {
  const [openAlertSuccess, setOpenAlertSuccess] = React.useState(false);
  const [openAlertError, setOpenAlertError] = React.useState(false);
  const navigate = useNavigate();

  const handleOpenAlertSuccess = () => {
    setOpenAlertSuccess(true);
  };

  const handleOpenAlertError = () => {
    setOpenAlertError(true);
  };

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlertError(false);
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlertSuccess(false);
  };
  const currentDate = new Date(); // Cria uma nova instância de Date com a data e hora atuais

  const [tournamentData, setTournamentData] = useState({
    id: tournamentDataOrigin.id,
    name: tournamentDataOrigin.name,
    description: tournamentDataOrigin.description,
    date_initial: new Date(tournamentDataOrigin.date_initial),
    date_final: new Date(tournamentDataOrigin.date_final),
  });

  const [stages, setStages] = useState(tournamentDataOrigin.stages);

  useEffect(() => {
    const updatedStages = stages.map((stage) => {
      const updatedQuestions = stage.questions.map((question) => {
        const updatedAlternatives = question.alternatives.map((alternative) => {
          return {
            ...alternative,
            is_correct: alternative.is_correct === 1 ? true : false,
          };
        });

        return {
          ...question,
          alternatives: updatedAlternatives,
        };
      });

      return {
        ...stage,
        questions: updatedQuestions,
      };
    });

    setStages(updatedStages);
  }, []);

  const handleTournamentChange = (name, value) => {
    setTournamentData({ ...tournamentData, [name]: value });
  };

  const handleStageChange = (event, index) => {
    const { name, value } = event.target;
    const updatedStages = [...stages];
    updatedStages[index][name] = value;
    setStages(updatedStages);
  };

  const handleQuestionChange = (event, stageIndex, questionIndex) => {
    const { name, value } = event.target;
    const updatedStages = [...stages];
    updatedStages[stageIndex].questions[questionIndex][name] = value;
    setStages(updatedStages);
  };

  const addStage = () => {
    const previousStage = stages[stages.length - 1];
    const hasQuestions =
      previousStage &&
      previousStage.questions.some(
        (question) => question.question.trim() !== ''
      );

    if (hasQuestions) {
      setStages([...stages, { order: stages.length + 1, questions: [] }]);
    } else {
      alert('A etapa anterior deve ter pelo menos uma pergunta preenchida.');
    }
  };

  const addQuestion = (stageIndex) => {
    const updatedStages = [...stages];
    updatedStages[stageIndex].questions.push({
      etapa_id: stages[stageIndex].order,
      order: updatedStages[stageIndex].questions.length + 1,
      question: '',
      points: 10,
      alternatives: [
        {
          pergunta_order: 1,
          alternative: 'A',
          description: '',
          is_correct: false,
        },
        {
          pergunta_order: 1,
          alternative: 'B',
          description: '',
          is_correct: false,
        },
        {
          pergunta_order: 1,
          alternative: 'C',
          description: '',
          is_correct: false,
        },
        {
          pergunta_order: 1,
          alternative: 'D',
          description: '',
          is_correct: false,
        },
      ],
    });
    setStages(updatedStages);
  };

  const handleAlternativeChange = (
    event,
    stageIndex,
    questionIndex,
    altIndex
  ) => {
    const { name, value } = event.target;
    const updatedStages = [...stages];
    updatedStages[stageIndex].questions[questionIndex].alternatives[altIndex][
      name
    ] = value;
    setStages(updatedStages);
  };

  const handleRadioChange = (event, stageIndex, questionIndex, altIndex) => {
    const { value } = event.target;
    const updatedStages = [...stages];
    updatedStages[stageIndex].questions[questionIndex].alternatives.forEach(
      (alternative, index) => {
        alternative.is_correct = index === altIndex; // Define a alternativa correta com base no índice do radio button
      }
    );
    setStages(updatedStages);
  };

  const handleRemoveStage = (stageIndex) => {
    const updatedStages = [...stages];
    updatedStages.splice(stageIndex, 1); // Remove a etapa
    setStages(updatedStages);
  };

  const handleRemoveQuestion = (stageIndex, questionIndex) => {
    const updatedStages = [...stages];
    updatedStages[stageIndex].questions.splice(questionIndex, 1); // Remove a pergunta
    setStages(updatedStages);
  };

  const handleJSONExport = () => {
    // Aqui você pode construir o objeto JSON final e enviar para o backend.
    const jsonData = {
      tournaments: {
        ...tournamentData,
        stages: stages,
      },
    };

    const jsonString = JSON.stringify(jsonData);

    console.log(jsonString);
  };


  const handleUpdateTournament = async () => {
    const previousStage = stages[stages.length - 1];
    const hasQuestions = previousStage?.questions
      ? previousStage.questions.some(
          (question) => question.question.trim() !== ''
        )
      : undefined;
    const hasStageLevel = previousStage?.stage_level
      ? previousStage?.stage_level?.trim() !== ''
      : undefined;
    const hasSuccessMessage = previousStage?.success_message
      ? previousStage?.success_message?.trim() !== ''
      : undefined;
    const hasFailureMessage = previousStage?.failure_message
      ? previousStage?.failure_message?.trim() !== ''
      : undefined;
    console.log({ previousStage }, { hasQuestions });

    if (!hasStageLevel) {
      alert(`Preencha o nível etapa ${previousStage?.order} `);
      return;
    }

    if (!hasSuccessMessage) {
      alert(`Preencha a mensagem de sucesso da etapa ${previousStage?.order} `);
      return;
    }

    if (!hasFailureMessage) {
      alert(`Preencha a mensagem de erro da etapa ${previousStage?.order} `);
      return;
    }

    if (hasQuestions) {
      setStages([...stages, { order: stages.length + 1, questions: [] }]);
    } else {
      alert(
        `A etapa ${previousStage?.order} deve ter pelo menos uma pergunta preenchida.`
      );
      return;
    }

    const formattedTournamentData = {
      ...tournamentData,
      date_initial: FormatDate(tournamentData.date_initial),
      date_final: FormatDate(tournamentData.date_final),
      stages: stages,
    };

    const jsonData = {
      tournaments: formattedTournamentData,
    };

    const token = localStorage.getItem('token');
    try {
      await tournamentService.updateTournament(
        jsonData.tournaments.id,
        jsonData,
        token
      );

      const response = await tournamentService.getTournaments(token);

      navigate('/tournaments', { state: { showToastUpdate: true } });
      handleOpenAlertSuccess();
      // handleClose();
    } catch (error) {
      handleOpenAlertError();
      console.error('Erro ao criar torneios:', error);
      throw error; // Você pode lidar com o erro ou lançá-lo para quem chama a função.
    }
  };

  return (
    <Div>
      <FormCreateTournament
        tournamentData={tournamentData}
        stages={stages}
        handleTournamentChange={handleTournamentChange}
        handleStageChange={handleStageChange}
        addStage={addStage}
        addQuestion={addQuestion}
        handleQuestionChange={handleQuestionChange}
        handleSubmitTournament={handleUpdateTournament}
      />
      {stages.map((stage, stageIndex) => (
        <Paper key={stageIndex} style={{ padding: '16px', margin: '16px 0' }}>
          <StageForm
            key={stageIndex}
            stage={stage}
            stageIndex={stageIndex}
            handleStageChange={handleStageChange}
            addQuestion={addQuestion}
            handleQuestionChange={handleQuestionChange}
          />

          <Button onClick={() => handleRemoveStage(stageIndex)}>
            Excluir Etapa
          </Button>

          {stage.questions.map((question, questionIndex) => (
            <div key={questionIndex}>
              <QuestionForm
                key={questionIndex}
                question={question}
                stageIndex={stageIndex}
                questionIndex={questionIndex}
                handleQuestionChange={handleQuestionChange}
                handleAlternativeChange={handleAlternativeChange}
                handleRadioChange={handleRadioChange}
              />

              <Button
                onClick={() => handleRemoveQuestion(stageIndex, questionIndex)}
              >
                Excluir Pergunta
              </Button>
            </div>
          ))}
        </Paper>
      ))}
      <Button onClick={addStage}>Adicionar Etapa</Button>
      <Button onClick={handleUpdateTournament}>Editar Torneio</Button>

      <CustomAlert
        open={openAlertSuccess}
        onClose={handleCloseAlertSuccess}
        severity='success' // Pode ser 'error', 'warning', 'info', ou 'success'
        message='Torneio Editado com sucesso!'
      />

      <CustomAlert
        open={openAlertError}
        onClose={handleCloseAlertError}
        severity='error' // Pode ser 'error', 'warning', 'info', ou 'success'
        message='Erro ao editar torneio!'
      />
    </Div>
  );
}

export default TournamentEditor;
