import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useQuery } from 'react-query';
import { tournamentService } from 'app/services/tournament-service';
import { useTournament } from '../../TournamentsAppProvider';
import useTournamentsApp from '../../hooks/useTournamentsApp';
import ModalConfirm from 'app/pages/components/ModalConfirm.js/ModalConfirm';
import CustomAlert from 'app/pages/components/Alerts/CustomAlert';
import { format } from 'date-fns';
import { useNavigate } from 'react-router';
import { useTranslation } from "react-i18next";


function createData(name, date_initial, action) {
  const formattedDate = format(new Date(date_initial), 'dd/MM/yyyy');

  return {
    name,
    date_initial: formattedDate,
    action,
  };
}


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Nome',
  },
  {
    id: 'date_initial',
    numeric: false,
    disablePadding: false,
    label: 'Data liberação',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Ações',
  },

];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ backgroundColor: '#161e54' }} >
      <TableRow >
        <TableCell padding="checkbox">

        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontSize: 16, color: '#fff' }}

          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      <Tooltip title="Filter list">
        <IconButton>
          <FilterListIcon />
        </IconButton>
      </Tooltip>


    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function TournamentList() {
  const { t } = useTranslation();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [tournamentIdToDelete, setTournamentIdToDelete] = React.useState(null);
  const [openAlertSuccess, setOpenAlertSuccess] = React.useState(false);
  const [openAlertError, setOpenAlertError] = React.useState(false);

  const { tournaments, setTournaments, getTournaments } = useTournament();
  const [tableData, setTableData] = React.useState([]);
  const navigate = useNavigate();


  const token = localStorage.getItem("token");

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getTournaments(token);
        setTournaments(res);


      } catch (error) {
        console.log(error);

      }
    };

    fetchData(); 

  }, []);

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlertSuccess(false);
  }

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlertError(false);
  }

  const handleConfirmDelete = (tournamenteId) => {
    setTournamentIdToDelete(tournamenteId);
    setOpenConfirmation(true);
  };

  const handleViewTournament = async (tournamenteId) => {
    try {
     
      navigate(`/tournament-details/${tournamenteId}`);


    }
    catch (error) {
      console.log(error);
    }



  }

  const handleEditTournament = async (tournamenteId) => {
    try {
           // Passe os dados como um parâmetro de rota
      navigate(`/tournament-edit/${tournamenteId}`);


    }
    catch (error) {
      console.log(error);
    }
   

  }


  const rows = tournaments ? tournaments.map((tournament) => {

    const iconsActions =
      <div>
        <Tooltip title="Delete">
          <IconButton id={tournament.id} sx={{ color: '#E73145' }}>
            <DeleteIcon onClick={() => {
              handleConfirmDelete(tournament.id)
            }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Editar" sx={{ color: '#F39711' }}>
          <IconButton id={tournament.id}>
            <ModeEditIcon onClick={() => {
              handleEditTournament(tournament.id)
            }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Visualizar">
          <IconButton id={tournament.id} sx={{ color: '#2EB5C9' }}>
            <RemoveRedEyeIcon onClick={() => {
              handleViewTournament(tournament.id)
            }} />
          </IconButton>
        </Tooltip>

      </div>
      ;



    return createData(tournament.name, tournament.date_initial, iconsActions);
  }) : [];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // eslint-disable-next-line react-hooks/rules-of-hooks

  const visibleRows = React.useMemo(() => {
    if (rows.length > 0) {
      return stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
    }
    return [];
  }, [rows, order, orderBy, page, rowsPerPage]);



  return (
    <Box sx={{ width: '100%' }}>
      <ModalConfirm
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        header="Confirmação de exclusão"
        message="Tem certeza de que deseja excluir este torneio?"
        onConfirm={async () => {
          try {
            await tournamentService.deleteTournament(tournamentIdToDelete, token);

            // Feche o modal de confirmação
            setOpenConfirmation(false);

            setTournamentIdToDelete(null);
            setOpenAlertSuccess(true);
            const newTournaments = tournaments.filter((tournament) => tournament.id !== tournamentIdToDelete);

            setTournaments(newTournaments);

            const newTableData = tableData.filter((tournament) => tournament.id !== tournamentIdToDelete);
            setTableData(newTableData);
          } catch (error) {
            console.log(error);
            setOpenAlertError(true);
          }
        }}
      />

      <CustomAlert
        open={openAlertSuccess}
        onClose={handleCloseAlertSuccess}
        severity="success" // Pode ser 'error', 'warning', 'info', ou 'success'
        message="Torneio excluído com sucesso!"
      />

      <CustomAlert
        open={openAlertError}
        onClose={handleCloseAlertError}
        severity="error" // Pode ser 'error', 'warning', 'info', ou 'success'
        message="Erro ao excluir torneio!"
      />
      <Paper sx={{ width: '100%', mb: 2 }}>

        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>

              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.name)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.name}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">

                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.date_initial}</TableCell>
                    <TableCell align="left">{row.action}</TableCell>

                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          // labelRowsPerPage={t('pagination.rowsPerPage')}
          // labelDisplayedRows={({ from, to, count }) =>
          //   `${from}-${to} ${t('pagination.from')} ${count}`
          // }
        />
      </Paper>

    </Box>
  );

}