import React from 'react';
import Avatar from "@mui/material/Avatar";
import { Button } from "@mui/material";
import ContentHeader from "../../../layouts/shared/headers/ContentHeader";
import List from "@mui/material/List";
import MenuItem from "@mui/material/MenuItem";
import SettingsIcon from '@mui/icons-material/Settings';
import styled from "@emotion/styled";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { ASSET_AVATARS } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import useJumboAuth from '@jumbo/hooks/useJumboAuth';



const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    padding: theme.spacing(0, 1),

    '&:hover': {
        backgroundColor: 'transparent',
    },

    '& .MuiTouchRipple-root': {
        display: 'none'
    }

}));

const Item = styled("div")({
    textAlign: 'center',
});


const Header = ({ User }) => {
    
    const { setAuthToken, authUser } = useJumboAuth();

    const ProfilePicture = getAssetPath(`${ASSET_AVATARS}/avatar3.jpg`, `72x72`);

    console.log('USer', User.scores);

    const AuthName = User?.name;
    const AuthEmail = User?.email;
    const AuthScoreTotal = User?.scores.length > 0 ? User.scores[0].total_score : 'No scores available';
    
    return (
        <ContentHeader
            avatar={
                <Avatar
                    sx={{ width: 72, height: 72 }}
                    alt={"Remy Sharp"}
                    src={ProfilePicture}
                />
            }
            title={AuthName}
            subheader={<Typography fontSize={12} variant={'body1'} color={'inherit'} mt={.5}>{AuthEmail}</Typography>}
            children={
                <Stack
                    direction={"row"}
                    justifyContent={"space-evenly"}
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                    sx={{
                        mx: 1
                    }}
                >

                    <Item>
                        <Typography variant={"h2"} color={'inherit'} mb={0}>{AuthScoreTotal}</Typography>
                        <Typography variant={'body1'} fontSize={16}>Pontos</Typography>
                    </Item>

                </Stack>
            }


            sx={{
                position: 'relative',
                zIndex: 1,

                '& .MuiCardHeader-action': {
                    alignSelf: 'center'
                }
            }}
        />
    );
};

export default Header;
