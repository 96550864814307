import React from 'react';

import { Button } from "@mui/material";
import ContentHeader from "../../../layouts/shared/headers/ContentHeader";

import MenuItem from "@mui/material/MenuItem";

import styled from "@emotion/styled";

import Stack from "@mui/material/Stack";

import AddIcon from '@mui/icons-material/Add';
import { useJumboTheme } from '@jumbo/hooks';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useTournament } from '../../tournament/TournamentsAppProvider';
import { userService } from 'app/services/user-service';
import CustomAlert from 'app/pages/components/Alerts/CustomAlert';
import { useUsers } from '../UsersAppProvider';



const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(0, 1),

  '&:hover': {
    backgroundColor: 'transparent',
  },

  '& .MuiTouchRipple-root': {
    display: 'none'
  }

}));



const Item = styled("div")({
  textAlign: 'center',
});


const HeaderUsers = () => {

  const [open, setOpen] = React.useState(false);
  const [openAlertSuccess, setOpenAlertSuccess] = React.useState(false);
  const [openAlertError, setOpenAlertError] = React.useState(false);

  const token = localStorage.getItem("token");

  const { users, setUsers, getUsers } = useUsers();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getUsers(token);
        setUsers(res);
      } catch (error) {
        console.log(error);
        setOpenAlertError(true);
      }
    };

    fetchData();
  }, []);

  const handleOpenAlertSuccess = () => {
    setOpenAlertSuccess(true)
  };

  const handleOpenAlertError = () => {
    setOpenAlertError(true)
  };

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlertError(false);
  }

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlertSuccess(false);
  };



  const handleClickOpen = () => {

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const { theme } = useJumboTheme();


  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordConfirmation, setPasswordConfirmation] = React.useState('');

  const [formErrors, setFormErrors] = React.useState({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    senhaMatch: '',
    emailExists: ''
  });






  const formDataUser = {
    name: name,
    email: email,
    password: password,
    password_confirmation: passwordConfirmation

  }

  const validateForm = () => {
    const errors = {};

    if (formDataUser.name.trim() === '') {
      errors.name = 'Campo Nome é obrigatório';
    }

    if (formDataUser.email.trim() === '') {
      errors.email = 'Campo Email é obrigatório';
    }

    if (formDataUser.password.trim() === '') {
      errors.password = 'Campo Senha é obrigatório';
    }

    if (formDataUser.password_confirmation.trim() === '') {
      errors.password_confirmation = 'Campo Confirmação de Senha é obrigatório';
    }

    if (formDataUser.password !== formDataUser.password_confirmation) {
      errors.senhaMatch = 'A senha e a confirmação de senha não coincidem';
    }

    if (users.some((user) => user.email === formDataUser.email)) {
      errors.emailExists = 'Este email já está em uso';
    }

    return errors;
  };



  const handleSubmitUser = async (e) => {
    e.preventDefault();

    try {
      const errors = validateForm();
      if (Object.keys(errors).length === 0) {
        await userService.createUser(formDataUser, token)

        const response = await userService.getUsers(token);
        setUsers(response);

        handleOpenAlertSuccess();
        handleClose();
      } else {

        setFormErrors(errors);
      }


    }
    catch (error) {
      handleOpenAlertError();
      console.error("Erro ao criar torneios:", error);
      throw error; // Você pode lidar com o erro ou lançá-lo para quem chama a função.
    }

  }


  return (
    <ContentHeader
      title={"Usuários"}
      children={
        <Stack
          direction={"row"}
          justifyContent={"space-evenly"}
          spacing={2}
          sx={{
            mx: 1
          }}>
          <Button
            disableRipple
            variant="text"
            startIcon={<AddIcon />}
            sx={{
              color: 'common.white',
              textTransform: 'none',
              backgroundColor: '#2EB5C9',
              pointer: 'cursor',
              '&:hover': {
                color: 'common.black',
                backgroundColor: 'transparent'
              }
            }}
            onClick={handleClickOpen}>
            Criar Novo Usuário
          </Button>


          <div>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Criar Usuário</DialogTitle>

              <DialogContent>
                <DialogContentText>Preencha o formulário abaixo:</DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Nome"
                  type="text"
                  name="name"
                  fullWidth
                  onChange={(event) => setName(event.target.value)}
                  error={!!formErrors.name}
                  helperText={formErrors.name}

                />
                <TextField
                  margin="dense"
                  label="Email"
                  type="email"
                  name="email"
                  fullWidth
                  onChange={(event) => setEmail(event.target.value)}
                  error={!!formErrors.email || !!formErrors.emailExists}
                  helperText={formErrors.email || formErrors.emailExists}




                />
                <TextField
                  margin="dense"
                  label="Senha"
                  type="password"
                  name="senha"
                  fullWidth
                  onChange={(event) => setPassword(event.target.value)}
                  error={!!formErrors.password || !!formErrors.senhaMatch}
                  helperText={formErrors.password || formErrors.senhaMatch}


                />
                <TextField
                  margin="dense"
                  label="Confirmação de Senha"
                  type="password"
                  name="confirmSenha"
                  fullWidth
                  onChange={(event) => setPasswordConfirmation(event.target.value)}
                  error={!!formErrors.password_confirmation || !!formErrors.senhaMatch}
                  helperText={formErrors.password_confirmation || formErrors.senhaMatch}

                />
              </DialogContent>


              <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleSubmitUser}>Criar</Button>
              </DialogActions>
            </Dialog>
          </div>



          <CustomAlert
            open={openAlertSuccess}
            onClose={handleCloseAlertSuccess}
            severity="success" // Pode ser 'error', 'warning', 'info', ou 'success'
            message="Usuário Criado com sucesso!"
          />

          <CustomAlert
            open={openAlertError}
            onClose={handleCloseAlertError}
            severity="error" // Pode ser 'error', 'warning', 'info', ou 'success'
            message="Erro ao criar usuário!"
          />





        </Stack>
      } />





  );
};

export default HeaderUsers;
