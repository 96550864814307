import React, { useState } from 'react';
import { TextField, Button, Grid, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import QuestionForm from './components/Forms/QuestionForm';
import { useQuestion } from './QuestionsAppProvider';
import QuestionFormEdit from './components/Forms/QuestionFormEdit';
import Div from '@jumbo/shared/Div';
import { useNavigate } from 'react-router-dom';

function QuestionEditor({
  questionData,
}) {
  const { getQuestions, setLevels, setCategories, getCategories, getLevels, levels, categories, createQuestions, updateQuestion } = useQuestion();
  const token = localStorage.getItem("token");
  const navigate = useNavigate(); // Obtém a função de navegação


  const [question, setQuestion] = useState(questionData)



  React.useEffect(() => {
    const fetchData = async () => {
      try {

        const respostaLevels = await getCategories(token);
        const respostaCategories = await getLevels(token);

        setLevels(respostaLevels);
        setCategories(respostaCategories);




      } catch (error) {
        console.log(error);

      }
    };

    fetchData();

  }, []);

  const handleQuestionChange = (e, questionId) => {
    setQuestion({
      ...question,
      [e.target.name]: e.target.value,
    });
  }

  const handleAlternativeChange = (e, altIndex) => {
    const { name, value } = e.target;
    const updatedAlternatives = [...question.alternatives];
    updatedAlternatives[altIndex] = {
      ...updatedAlternatives[altIndex],
      [name]: value,
    };
    setQuestion({
      ...question,
      alternatives: updatedAlternatives,
    });

  }

  const handleRadioChange = (e, altIndex) => {
    const updatedAlternatives = question.alternatives.map((alternative, index) => ({
      ...alternative,
      is_correct: index === altIndex,
    }));
    setQuestion({
      ...question,
      alternatives: updatedAlternatives,
    });



  }


  const handleUpdateQuestion = async (questionId) => {
    try {

      //transformar em json question
      const dataJson = JSON.stringify(question);

      // console.log(dataJson);

      const resposta = await updateQuestion(token, questionId, dataJson);

      navigate("/questions", { state: { showToastUpdate: true } });
      //handleOpenAlertSuccess();

    } catch (error) {
      console.log(error);

    }


  }
  return (
    <Div>
      <QuestionFormEdit
        key={question.id}
        question={question}
        alternatives={question.alternatives}
        categories={categories ? categories : []}
        levels={levels ? levels : []}
        questionIndex={question.id}
        handleQuestionChange={handleQuestionChange}
        handleAlternativeChange={handleAlternativeChange}
        handleRadioChange={handleRadioChange}
        handleUpdateQuestion={handleUpdateQuestion}
      />
    </Div>
  );
}

export default QuestionEditor;
