import { rankingService } from "app/services/ranking-service";
import { useContext, useState } from "react";
import RankingsAppContext from "./RankingsAppContext";

export function RankingAppProvider({
  children
}) {
  const [rankings, setRankings] = useState();

  const getRankings = token => {
    rankingService.getRankings(token).then(response => {
      setRankings(response);
      return response;
    }).catch(error => {
      console.error(error);
    });

  }

  return (
    <RankingsAppContext.Provider value={
      {
        rankings,
        setRankings,
        getRankings
      }
    }>
      {children}
    </RankingsAppContext.Provider>
  );
}

export function useRanking() {
  return useContext(RankingsAppContext);
}