import React from "react";
import UserProfile from "../pages/users/user-profile";
import Page from "@jumbo/shared/Page";
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
const userFromLocalStorage = localStorage.getItem("user");

let user = null;

try {
  
  user = JSON.parse(userFromLocalStorage);

  
} catch (error) {
  // Trate quaisquer erros de parsing aqui
  console.error("Erro ao fazer o parsing dos dados do usuário:", error);
}

const userRoutes = [
  {
    path: "/user/profile",
    element: <Page component={() =>
      <UserProfile User={user} />
    }

    />
  },

  {
    path: "/user/profile/:id",
    element: <Page component={() =>
      <UserProfile />
    }

    />
  },

];
export default userRoutes;