import axios from "./config";


const rankingService = {};

rankingService.getRankings = async (token) => {
  try {
    const response = await axios.get("/scores", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar rankings:", error);
    throw error; // Você pode lidar com o erro ou lançá-lo para quem chama a função.
  }
}


export {rankingService};