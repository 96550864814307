import React from 'react'
import Div from "@jumbo/shared/Div";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Grid from '@mui/material/Grid';
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { format } from 'date-fns';
import {ptBR} from 'date-fns/locale';

function FormCreateTournament(props) {
  const { tournamentData, stages, handleTournamentChange, handleStageChange, addStage, addQuestion, handleQuestionChange, handleSubmitTournament } = props;

  const handleDateChange = (value, name) => {

    handleTournamentChange(name, value)


  };



  const [value, setValue] = React.useState(new Date('2022-06-04T21:11:54'));
  return (
    <Div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '24ch' },
        }}
        noValidate
        autoComplete="off"
      >

        <Div sx={{ flexGrow: 1 }}>
          <Grid container spacing={4}>
            <Grid item xs>
              <TextField
                name="name"
                label="Nome do Torneio"
                value={tournamentData.name}
                onChange={
                  (event) => handleDateChange(event.target.value, "name")
                }
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs>
              <TextField
                id="outlined-multiline-static"

                multiline
                rows={1}
                name="description"
                label="Descrição do Torneio"
                value={tournamentData.description}
                onChange={(event) => handleDateChange(event.target.value, "description")}
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs>
              <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
                <DemoContainer components={['DateTimePicker']} style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center'

                }}>

                  <DateTimePicker
                    name="date_initial"
                    label="Data Inicial"
                    value={tournamentData.date_initial}
                    onChange={(value) => handleDateChange(value, "date_initial")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={tournamentData.date_initial ? format(tournamentData.date_initial, 'dd/MM/yyyy HH:mm') : ''}
                        InputProps={{ style: { width: '100%' } }}
                      />
                    )}
                    style={{ width: '100%' }}
                  />

                </DemoContainer>

              

              </LocalizationProvider>
            </Grid>
            <Grid item xs>
              <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
                

                <DemoContainer components={['DateTimePicker']} style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center'

                }}>


                  <DateTimePicker
                    name="date_final"
                    label="Data Final"
                    value={tournamentData.date_final}
                    onChange={(value) => handleDateChange(value, "date_final")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={tournamentData.date_final ? format(tournamentData.date_final, 'dd/MM/yyyy HH:mm') : ''}
                        InputProps={{ style: { width: '100%' } }}
                      />
                    )}
                    style={{ width: '100%' }}
                  />
                </DemoContainer>

              </LocalizationProvider>
            </Grid>

          </Grid>

        </Div>



      </Box>

    </Div>

  )
}

export default FormCreateTournament