import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import { useJumboTheme } from '@jumbo/hooks';
import ContentHeader from 'app/layouts/shared/headers/ContentHeader';
import React from 'react'
import TournamentCreator from './TournamentCreator';
import { TournamentAppProvider } from './TournamentsAppProvider';


const TournamentCreate = () => {
  const { theme } = useJumboTheme();


  return (
    <TournamentAppProvider>
      <ContentHeader
        title={"Criar Torneio"}

      />
      <JumboContentLayout
        layoutOptions={{
          header: {
            sx: {
              mt: -4,
              mb: -7.25,
              mx: { xs: -4, lg: -6 },
              p: { xs: theme.spacing(6, 4, 11), lg: theme.spacing(6, 6, 11) },
              backgroundSize: 'cover',
              color: 'common.white',
              position: 'relative',

              '&::after': {
                display: 'inline-block',
                position: 'absolute',
                content: `''`,
                inset: 0,

              }
            }
          },
          sidebar: {
            sx: {
              mr: 3.75,
              width: { xs: '100%', lg: '33%' },
              [theme.breakpoints.down('lg')]: {
                minHeight: 0,
                mr: 0,
                order: 2
              }
            }
          },
          wrapper: {
            sx: {
              [theme.breakpoints.down('lg')]: {
                flexDirection: 'column'
              }
            }
          },
          main: {
            sx: {
              [theme.breakpoints.down('lg')]: {
                minHeight: 0
              }
            }
          }
        }}
      >
        <TournamentCreator />
      </JumboContentLayout>
    </TournamentAppProvider>


  );
};

export default TournamentCreate;