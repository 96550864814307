import { userService } from 'app/services/user-service';
import React, { useContext, useState } from 'react';

import UsersAppContext from './UsersAppContext';

const storedUser = localStorage.getItem("users");
const storedToken = localStorage.getItem("token");


export function UsersAppProvider({ children }) {
  const [users, setUsers] = useState();
  const [user, setUser] = useState({});



  const getUsers = (token) => {
    userService.getUsers(token)
      .then((response) => {
        
        setUsers(response);

        return response;
      })
      .catch((error) => {
        console.error(error);
      });


  }
  return (
    <UsersAppContext.Provider value={{ users, setUsers, user, setUser, getUsers }}>
      {children}
    </UsersAppContext.Provider>
  );
}

export function useUsers() {
  return useContext(UsersAppContext);
}

