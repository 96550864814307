import axios from "./config";


const tournamentService = {};
tournamentService.getTournaments = async (token) => {
  try {

  
    const response = await axios.get("/tournaments", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  

    return response.data;
  } catch (error) {
    console.error("Erro ao buscar torneios:", error);
    throw error; // Você pode lidar com o erro ou lançá-lo para quem chama a função.
  }
};

tournamentService.createTournament = async (tournament, token) => {
  try {
    
    const response = await axios.post("/tournaments", tournament, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  
    return response.data;
  } catch (error) {
    console.error("Erro ao criar torneios:", error);
    throw error; // Você pode lidar com o erro ou lançá-lo para quem chama a função.
  }
}


tournamentService.deleteTournament = async (id, token) => {
  try {
    const response = await axios.delete(`/tournaments/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });


    return response.data;
  } catch (error) {
    console.error("Erro ao deletar usuário:", error);
    throw error; // Você pode lidar com o erro ou lançá-lo para quem chama a função.
  }
}

tournamentService.getTournament = async (id, token) => {
  try {
    const response = await axios.get(`/tournaments/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Erro ao buscar torneio:", error);
    throw error; // Você pode lidar com o erro ou lançá-lo para quem chama a função.
  }
}

tournamentService.updateTournament = async (id,tournament, token) => { 
  try {
    const response = await axios.put(`/tournaments/${id}`, tournament, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Erro ao atualizar torneio:", error);
    throw error; // Você pode lidar com o erro ou lançá-lo para quem chama a função.
  }
}

export { tournamentService };