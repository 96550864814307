import Page from "@jumbo/shared/Page";
import Question from "app/pages/questions/Question";
import QuestionCreate from "app/pages/questions/QuestionCreate";
import QuestionEdit from "app/pages/questions/QuestionEdit";


import React from "react";

const questionRoutes = [
    {
        path: "/questions",
        element: <Page component={Question} />
    },

    {
        path: "/questions/create",
        element: <Page component={QuestionCreate} />
    },
    // {
    //     path: "/question-details/:id",
    //     element: <Page component={TournamentDetails} />
    // },
    {
        path: "/question-edit/:id",
        element: <Page component={QuestionEdit} />
    },

];

export default questionRoutes;
