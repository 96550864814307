import jwtAxios from "axios";

const jwtAuthAxios = jwtAxios.create({
    baseURL: process.env.REACT_APP_API_KEY,
    headers: {
        'Content-Type': 'application/json'
    }
});

// jwtAuthAxios.interceptors.response.use(
//     res => res,
//     err => {
//         console.log("err", err);
//         if (err.response && err.response.data.type === "token-invalid") {
//             localStorage.removeItem('token');
//             localStorage.removeItem('user');
//             window.location.href = '/user/login';
//         }
//         return Promise.reject(err);
//     }
// );

jwtAuthAxios.interceptors.response.use(
    (response) => {

        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {

        } else {
            throw error;
        }
    }
);
// export const setAuthToken = (token) => {
//     if(token) {
//         jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
//         sessionStorage.setItem('token', token);
//     } else {
//         delete jwtAuthAxios.defaults.headers.common['Authorization'];
//         sessionStorage.removeItem('token');
//     }
// };



// export const getAuthToken = () => {
//     return sessionStorage.getItem("token");
// };

//todo: define interceptors and other configuration like baseURL, headers etc. here
export default jwtAuthAxios;