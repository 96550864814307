import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { tournamentService } from 'app/services/tournament-service';

const TournamentDetails = () => {

  // const { userId } = useParams();

  // Estado para controlar a abertura/fechamento das perguntas
  const [expandedQuestions, setExpandedQuestions] = useState([]);
  const token = localStorage.getItem("token");

  const [tournamentData, setTournamentData] = useState(null); // Inicialize com null

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tournamentId = window.location.pathname.split('/').pop();
        const res = await tournamentService.getTournament(tournamentId, token)

        if (!res) {
          return;
        }

        if (res) {
          setTournamentData(res);

        } else {
          console.error("No tournaments in the list.");
        }
      } catch (error) {
        console.error("Error fetching tournaments:", error);
      }
    };

    fetchData();
  }, []);

  const toggleQuestion = (questionId) => {
    // Verificar se a pergunta está na lista de expandidas
    const isExpanded = expandedQuestions.includes(questionId);

    // Se estiver expandida, remova-a; caso contrário, adicione-a à lista
    if (isExpanded) {
      setExpandedQuestions(expandedQuestions.filter((id) => id !== questionId));
    } else {
      setExpandedQuestions([...expandedQuestions, questionId]);
    }
  };






  return (
    <Paper elevation={3} style={{ padding: '16px' }}>
      {tournamentData ? (
        <>
          <Typography variant="h4" gutterBottom>
            {tournamentData.name}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {tournamentData.description}
          </Typography>
          <Typography variant="body2" gutterBottom>
            Data de início: {format(new Date(tournamentData.date_initial), 'dd/MM/yyyy HH:mm')}

          </Typography>
          <Typography variant="body2" gutterBottom>
            Data de término: {format(new Date(tournamentData.date_final), 'dd/MM/yyyy HH:mm')}
          </Typography>

          {/* Renderizar as etapas do torneio */}
          <List>
            {tournamentData.stages.map((stage) => (
              <React.Fragment key={stage.id}>
                <ListItem button onClick={() => toggleQuestion(stage.id)}>
                  <ListItemText primary={`Etapa ${stage.order}`} />
                </ListItem>
                <Collapse in={expandedQuestions.includes(stage.id)}>
                  <List>
                    <Typography variant="body2" gutterBottom>
                      Perguntas:
                    </Typography>
                    {stage.questions.map((question) => (
                      <React.Fragment key={question.id}>
                        <ListItem>
                          <ListItemText
                            primary={`${question.id} - ${question.question}`}
                          />
                        </ListItem>
                        <List>
                          {question.alternatives.map((alternative) => (
                            <ListItem key={alternative.id}>
                              <ListItemText
                                primary={`${alternative.alternative} - ${alternative.description}`}
                                style={{
                                  color: alternative.is_correct
                                    ? 'green'
                                    : 'red',
                                }}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </React.Fragment>
                    ))}
                  </List>
                </Collapse>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </>
      ) : (
        <Typography variant="body1">
          Torneio não encontrado ou carregando...
        </Typography>
      )}
    </Paper>
  );
}

export default TournamentDetails;


