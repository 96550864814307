import React, { useState } from 'react';
import {
  Button,
  TextField,
  Typography,
  Paper,
  Container,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import CustomAlert from '../components/Alerts/CustomAlert';
import { useNavigate } from 'react-router-dom';
import FormatDate from 'app/components/FormatDate/FormatDate';
import Div from '@jumbo/shared/Div';
import QuestionForm from './components/Forms/QuestionForm';
import { useQuestion } from './QuestionsAppProvider';

function QuestionCreator() {
  const [openAlertSuccess, setOpenAlertSuccess] = React.useState(false);
  const [openAlertError, setOpenAlertError] = React.useState(false);
  const navigate = useNavigate();
  const { getQuestions, setLevels, setCategories, getCategories, getLevels, levels, categories , createQuestions} = useQuestion();

  const token = localStorage.getItem("token");

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        
        const respostaLevels = await getCategories(token);
        const respostaCategories = await getLevels(token);
        
        setLevels(respostaLevels);
        setCategories(respostaCategories);




      } catch (error) {
        console.log(error);

      }
    };

    fetchData();

  }, []);

 

  const handleOpenAlertSuccess = () => {
    setOpenAlertSuccess(true);
  };

  const handleOpenAlertError = () => {
    setOpenAlertError(true);
  };

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlertError(false);
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlertSuccess(false);
  };

  const currentDate = new Date();

  const [questions, setQuestions] = useState([
    {
      order: 1,
      question: '',
      points: 10,
      category_id: 1,
      level_id:  1,
      alternatives: [
        { pergunta_order: 1, alternative: 'A', description: '', is_correct: false },
        { pergunta_order: 1, alternative: 'B', description: '', is_correct: false },
        { pergunta_order: 1, alternative: 'C', description: '', is_correct: false },
        { pergunta_order: 1, alternative: 'D', description: '', is_correct: false },
      ],
    },
  ]);

  const handleQuestionChange = (event, questionIndex) => {
    const { name, value } = event.target;
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex][name] = value;
    setQuestions(updatedQuestions);
  };

  const addQuestion = () => {
    setQuestions([...questions, {
      order: questions.length + 1, alternatives: [
        { pergunta_order: 1, alternative: 'A', description: '', is_correct: false },
        { pergunta_order: 1, alternative: 'B', description: '', is_correct: false },
        { pergunta_order: 1, alternative: 'C', description: '', is_correct: false },
        { pergunta_order: 1, alternative: 'D', description: '', is_correct: false },
      ]
    }]);
  };

  const handleAlternativeChange = (event, questionIndex, altIndex) => {
    const { name, value } = event.target;
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].alternatives[altIndex][name] = value;
    setQuestions(updatedQuestions);
  };

  const handleRadioChange = (event, questionIndex, altIndex) => {
    const { value } = event.target;
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].alternatives.forEach((alternative, index) => {
      alternative.is_correct = index === altIndex;
    });
    setQuestions(updatedQuestions);
  };

  const handleRemoveQuestion = (questionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(questionIndex, 1); // Remove a pergunta
    setQuestions(updatedQuestions);
  };

  const handleJSONExport = () => {
    const jsonData = {
      questions: questions,
    };



    // Exportar jsonData...
  };

  const handleSubmitQuestions = async () => {
    // Lógica para enviar as perguntas para o servidor
    const jsonData = {
      questions: questions,
    };

    try {
      const res = createQuestions(token, jsonData);

      navigate("/questions", { state: { showToast: true } });
      handleOpenAlertSuccess();
    } catch (error) {
      handleOpenAlertError();
      console.error("Erro ao criar perguntas:", error);
      throw error;
    }
  };


  return (
    <Div>
      <Button onClick={addQuestion}>Adicionar Pergunta</Button>

      {questions.map((question, questionIndex) => (
        <QuestionForm
          key={questionIndex}
          question={question}
          alternatives={question.alternatives}
          categories={categories ? categories : []}
          levels={levels ? levels : []}
          questionIndex={questionIndex}
          handleQuestionChange={handleQuestionChange}
          handleAlternativeChange={handleAlternativeChange}
          handleRadioChange={handleRadioChange}
          handleRemoveQuestion={handleRemoveQuestion}
        />
      ))}

      <Button onClick={handleSubmitQuestions}>Salvar Perguntas</Button>

      <CustomAlert
        open={openAlertSuccess}
        onClose={handleCloseAlertSuccess}
        severity="success"
        message="Perguntas criadas com sucesso!"
      />

      <CustomAlert
        open={openAlertError}
        onClose={handleCloseAlertError}
        severity="error"
        message="Erro ao criar perguntas!"
      />
    </Div>
  );
}

export default QuestionCreator;
