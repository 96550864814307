import React, { useEffect } from 'react';
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { RankingAppProvider } from './RankingsAppProvider';
import Header from './components/Header';
import RankingList from './components/RankingsList/RankingList';

const Ranking = () => {
  const { theme } = useJumboTheme();

  return (
    <RankingAppProvider>
      <Header />
      <JumboContentLayout
                layoutOptions={{
                    header: {
                        sx: {
                            mt: -4,
                            mb: -7.25,
                            mx: { xs: -4, lg: -6 },
                            p: { xs: theme.spacing(6, 4, 11), lg: theme.spacing(6, 6, 11) },
                            backgroundSize: 'cover',
                            color: 'common.white',
                            position: 'relative',

                            '&::after': {
                                display: 'inline-block',
                                position: 'absolute',
                                content: `''`,
                                inset: 0,

                            }
                        }
                    },
                    sidebar: {
                        sx: {
                            mr: 3.75,
                            width: { xs: '100%', lg: '33%' },
                            [theme.breakpoints.down('lg')]: {
                                minHeight: 0,
                                mr: 0,
                                order: 2
                            }
                        }
                    },
                    wrapper: {
                        sx: {
                            [theme.breakpoints.down('lg')]: {
                                flexDirection: 'column'
                            }
                        }
                    },
                    main: {
                        sx: {
                            [theme.breakpoints.down('lg')]: {
                                minHeight: 0
                            }
                        }
                    }
                }}
            >
                <RankingList/>
              
            </JumboContentLayout>
    </RankingAppProvider>
  )
}

export default Ranking;