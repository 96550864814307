import React from "react";
import Page from "@jumbo/shared/Page";
import Login from "../pages/auth-pages/login";
import Signup from "../pages/auth-pages/signup";

const authRoutes = [
    {
        path: "/user/login",
        element: <Page component={Login} layout={"solo-page"} />,
    },
    {
      path: "/user/signup",
      element: <Page component={Signup} layout={"solo-page"} />
    },
    
];

export default authRoutes;
