import React from 'react';

import { Button } from "@mui/material";
import ContentHeader from "../../../layouts/shared/headers/ContentHeader";

import MenuItem from "@mui/material/MenuItem";

import styled from "@emotion/styled";

import Stack from "@mui/material/Stack";

import AddIcon from '@mui/icons-material/Add';
import { useJumboTheme } from '@jumbo/hooks';
// import TextField from '@mui/material/TextField';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { tournamentService } from 'app/services/tournament-service';
import { useTournament } from '../../tournament/TournamentsAppProvider';
import { useNavigate } from 'react-router-dom';
//import useTournamentsApp from '../../tournament/hooks/useTournamentsApp';

// import Snackbar from '@mui/material/Snackbar';
// import MuiAlert from '@mui/material/Alert';
// import CustomAlert from 'app/pages/components/Alerts/CustomAlert';


const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(0, 1),

  '&:hover': {
    backgroundColor: 'transparent',
  },

  '& .MuiTouchRipple-root': {
    display: 'none'
  }

}));



const Item = styled("div")({
  textAlign: 'center',
});


const HeaderQuestion = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [openAlertSuccess, setOpenAlertSuccess] = React.useState(false);
  const [openAlertError, setOpenAlertError] = React.useState(false);


  const { setTournaments } = useTournament();

  const handleOpenAlertSuccess = () => {
    setOpenAlertSuccess(true)
  };

  const handleOpenAlertError = () => {
    setOpenAlertError(true)
  };

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlertError(false);
  }

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlertSuccess(false);
  };



  const handleCriarQuestion = () => {
    navigate("/questions/create");
  };

  const handleClose = () => {
    setOpen(false);
  };


  const { theme } = useJumboTheme();


  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [date_initial, setDateInitial] = React.useState(
    new Date()
  );
  const [date_final, setDateFinal] = React.useState(
    new Date()
  );



  const formDataTournament = {
    name: name,
    description: description,
    date_initial: formatDate(date_initial),
    date_final: formatDate(date_final)
  }

  function formatDate(date) {
    const formattedDate = new Date(date).toISOString();
    return formattedDate.substring(0, 10); // Retorna apenas a parte 'AAAA-MM-DD'
  }

  const handleSubmitTournament = async () => {

    try {
      await tournamentService.createTournament(formDataTournament)

      const response = await tournamentService.getTournaments();




      setTournaments(response);
      handleOpenAlertSuccess();
      handleClose();


    }
    catch (error) {
      handleOpenAlertError();
      console.error("Erro ao criar torneios:", error);
      throw error; // Você pode lidar com o erro ou lançá-lo para quem chama a função.
    }

  }


  return (
    <ContentHeader
      title={"Perguntas"}
      children={
        <Stack
          direction={"row"}
          justifyContent={"space-evenly"}
          spacing={2}
          sx={{
            mx: 1
          }}
        >
          <Button
            disableRipple
            variant="text"
            startIcon={<AddIcon />}
            sx={{
              color: 'common.white',
              textTransform: 'none',
              backgroundColor: '#2EB5C9',
              pointer: 'cursor',
              '&:hover': {
                color: 'common.black',
                backgroundColor: 'transparent'
              }
            }}
            onClick={handleCriarQuestion}>
            Criar Perguntas
          </Button>



          {/* <CustomAlert
            open={openAlertSuccess}
            onClose={handleCloseAlertSuccess}
            severity="success" // Pode ser 'error', 'warning', 'info', ou 'success'
            message="Torneio Criado com sucesso!"
          />

          <CustomAlert
            open={openAlertError}
            onClose={handleCloseAlertError}
            severity="error" // Pode ser 'error', 'warning', 'info', ou 'success'
            message="Erro ao criar torneio!"
          /> */}


        </Stack>
      }
    />

  );
};

export default HeaderQuestion;
