import Page from "@jumbo/shared/Page";
import Tournament from "app/pages/tournament/Tournament";
import TournamentCreate from "app/pages/tournament/TournamentCreate";
import TournamentDetails from "app/pages/tournament/TournamentDetails";
import TournamentEdit from "app/pages/tournament/TournamentEdit"
import React from "react";

const tournamentRoutes = [
    {
        path: "/tournaments",
        element: <Page component={Tournament} />
    },

    {
        path: "/tournaments/create",
        element: <Page component={TournamentCreate} />
    },
    {
        path: "/tournament-details/:id",
        element: <Page component={TournamentDetails} />
    },
    {
        path: "/tournament-edit/:id",
        element: <Page component={TournamentEdit} />
    },



];

export default tournamentRoutes;
