import { questionService } from 'app/services/question-service';
import React, { useContext, useState } from 'react';
import QuestionsAppContext from './QuestionsAppContext';



export function QuestionsAppProvider({ children }) {
  const [questions, setQuestions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [levels, setLevels] = useState([]);

  const getQuestions = (token) => {
    questionService.getQuestions(token)
      .then((response) => {
        setQuestions(response);
        return response;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const getCategories = (token) => {
    questionService.getCategories(token)
      .then((response) => {
        setCategories(response);
        return response;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const getLevels = (token) => {
    questionService.getLevels(token)
      .then((response) => {
        setLevels(response);
        return response;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const createQuestions = (token, question) => {
    questionService.createQuestions(token, question)
      .then((response) => {
        const updatedQuestions = [...questions, ...response];
        setQuestions(updatedQuestions);
      })
      .catch((error) => {
        console.error(error);
      });

  }

  const updateQuestion= (token, questionId, questionData) =>{
    questionService.updateQuestion(token, questionId, questionData)
    .then((response) => {
      const updatedQuestions = [...questions, ...response];
      setQuestions(updatedQuestions);
    })
    .catch((error) => {
      console.error(error);
    });
  }

  





  return (
    <QuestionsAppContext.Provider value={{ questions, categories, levels, setQuestions, setLevels, setCategories, getQuestions, getCategories, getLevels, createQuestions, updateQuestion }}>
      {children}
    </QuestionsAppContext.Provider>
  );
}

export function useQuestion() {

  return useContext(QuestionsAppContext);
}
