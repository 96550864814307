import React, { useEffect, useState } from 'react';
import Header from "./Header";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { alpha } from "@mui/material/styles";
import About from "./components/About";
import UserProfileSidebar from "./UserProfileSidebar";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { ASSET_AVATARS } from "../../../utils/constants/paths";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { userService } from 'app/services/user-service';

const UserProfile = ({ User }) => {
    const { theme } = useJumboTheme();

    const { id } = useParams(); // Obtém o valor do parâmetro "id" da rota
    const [userData, setUserData] = useState(null);
    const token = localStorage.getItem("token")

   

    useEffect(() => {
        try {
            userService.getUserById(id, token)
                .then((response) => {
                   
                    setUserData(response.user);
                    return response;
                })
                .catch((error) => {
                    console.error(error);
                });
        }
        catch (error) {
            console.log(error);
        }


    }, [id]);


    const userToDisplay = User || userData;

    if (!userData && !User) {
        return <div>Carregando...</div>;
    }

    return (
        <JumboContentLayout
        header={userToDisplay && <Header User={userToDisplay} />}
            sidebar={<UserProfileSidebar User={userToDisplay} />}
            layoutOptions={{
                header: {
                    sx: {
                        mt: -4,
                        mb: -7.25,
                        mx: { xs: -4, lg: -6 },
                        p: { xs: theme.spacing(6, 4, 11), lg: theme.spacing(6, 6, 11) },
                        background: `#002447 url(${getAssetPath(`${ASSET_IMAGES}/profile-bg.jpg`, "1920x580")}) no-repeat center`,
                        backgroundSize: 'cover',
                        color: 'common.white',
                        position: 'relative',

                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                            backgroundColor: alpha(theme.palette.common.black, .65)
                        }
                    }
                },
                sidebar: {
                    sx: {
                        mr: 3.75,
                        width: { xs: '100%', lg: '33%' },
                        [theme.breakpoints.down('lg')]: {
                            minHeight: 0,
                            mr: 0,
                            order: 2
                        }
                    }
                },
                wrapper: {
                    sx: {
                        [theme.breakpoints.down('lg')]: {
                            flexDirection: 'column'
                        }
                    }
                },
                main: {
                    sx: {
                        [theme.breakpoints.down('lg')]: {
                            minHeight: 0
                        }
                    }
                }
            }}
        >
            <About User={userToDisplay}/>

        </JumboContentLayout>
    );
};

export default UserProfile;
