import React from 'react';
import TabContext from "@mui/lab/TabContext";
import Div from "@jumbo/shared/Div";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import List from "@mui/material/List";
import { Button, ListItem, ListItemIcon, ListItemText, MenuItem, Select, TextField, Typography } from "@mui/material";
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import styled from "@emotion/styled";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { userService } from 'app/services/user-service';
import CustomAlert from 'app/pages/components/Alerts/CustomAlert';
import ModalConfirm from 'app/pages/components/ModalConfirm.js/ModalConfirm';
import { useNavigate } from 'react-router-dom';

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: 24,
    height: 48,
    width: 48,
    borderRadius: '50%',
    minWidth: 42,
    marginRight: 16,
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    border: `solid 1px ${theme.palette.divider}`
}));
const About = ({ User }) => {
    const [value, setValue] = React.useState('1');
    const id = User?.id;
    const [editedUser, setEditedUser] = React.useState({
        name: User?.name || '',
        email: User?.email || '',
    });
    const [selectedGame, setSelectedGame] = React.useState('');


    console.log(User)
    const token = localStorage.getItem("token")
    const [openAlertSuccess, setOpenAlertSuccess] = React.useState(false);
    const [openAlertError, setOpenAlertError] = React.useState(false);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const navigate = useNavigate();
    console.log(value);
    const scores = User?.scores || [];

    const handleChange = (event, newValue) => {
        setValue(newValue);

    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setEditedUser({
            ...editedUser,
            [name]: value,
        });
    };
    const handleScoreChange = (event) => {
        setSelectedGame(event.target.value);
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        setOpenConfirmation(true);


    };

    const handleCloseAlertSuccess = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlertSuccess(false);
    }

    const handleCloseAlertError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlertError(false);
    }

    return (

        <JumboCardQuick
            title={"About"}
            action={
                <TabContext value={value}>
                    <Div
                        sx={{
                            marginTop: -2.25,
                            marginBottom: -2.5,

                            '& .MuiTab-root': {
                                py: 2.5
                            }
                        }}
                    >
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Editar Perfil" value="1" />
                            <Tab label="Dados" value="2" />
                            <Tab label="Pontuação" value="3" />
                        </TabList>
                    </Div>
                </TabContext>
            }
            headerSx={{
                borderBottom: 1, borderColor: 'divider'
            }}
            sx={{ mb: 3.75 }}

        >
            {value === '1' && (
                <div>


                    <List
                        disablePadding
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            margin: theme => theme.spacing(0, -2),
                        }}
                    >

                        <form onSubmit={handleSubmit}>
                            <Div sx={{
                                display: 'flex',

                                flexWrap: 'wrap',
                                gap: 5
                            }}>
                                <Div sx={{
                                    marginLeft: 2
                                }}>
                                    <TextField
                                        fullWidth
                                        label="Nome"
                                        name="name"
                                        value={editedUser.name}
                                        onChange={handleInputChange}
                                        variant="outlined"
                                        margin="normal"
                                    />
                                    <TextField
                                        fullWidth
                                        label="Email"
                                        name="email"
                                        value={editedUser.email}
                                        onChange={handleInputChange}
                                        variant="outlined"
                                        margin="normal"
                                    />
                                </Div>
                                <Div sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Salvar
                                    </Button>
                                </Div>
                            </Div>


                        </form>

                    </List>
                </div>
            )}
            {value === '2' && (
                // Renderizar conteúdo para a guia 'Dados'
                <div>
                    <h1>Dados</h1>
                </div>
            )}
            {value === '3' && (
                <div>

                    {scores.map((score, scoreIndex) => (
                        <div>
                            <Div sx={{ flex: 1 }} key={scoreIndex}>
                                {scores.length > 0 ? (
                                    <div>
                                        <h3>Pontuação total: {score.total_score} pontos</h3>
                                        {score.details.map((detail, detailIndex) => (
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>{detail.game_name}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        {detail.score} pontos
                                                    </Typography>

                                                    {detail.tournament_name && (
                                                        <Typography>
                                                            Torneio: {detail.tournament_name}
                                                        </Typography>
                                                    )}
                                                </AccordionDetails>
                                            </Accordion>
                                        ))}

                                    </div>
                                )
                                    : (
                                        <div>No scores available.</div> // Display a message when the scores array is empty
                                    )
                                }
                            </Div>
                        </div>
                    ))}


                </div>
            )}

            <CustomAlert
                open={openAlertSuccess}
                onClose={handleCloseAlertSuccess}
                severity="success" // Pode ser 'error', 'warning', 'info', ou 'success'
                message="Usuário editado com sucesso!"
            />

            <CustomAlert
                open={openAlertError}
                onClose={handleCloseAlertError}
                severity="error" // Pode ser 'error', 'warning', 'info', ou 'success'
                message="Erro ao editar usuário!"
            />

            <ModalConfirm
                open={openConfirmation}
                onClose={() => setOpenConfirmation(false)}
                header="Confirmação de edição"
                message="Tem certeza de que deseja editar este usuário?"
                onConfirm={() => {
                    try {
                        userService.updateUser(id, editedUser, token)
                            .then((response) => {

                                setOpenConfirmation(false);
                                navigate("/users", { state: { showToastUpdateUser: true } });
                                return response;
                            }
                            )
                            .catch((error) => {
                                console.error(error);
                            }
                            );

                    } catch (error) {
                        console.log(error);
                        setOpenAlertError(true);
                    }
                }}
            />
        </JumboCardQuick>
    );
};

export default About;
