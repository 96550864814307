import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Stack, useMediaQuery } from "@mui/material";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import TournamentList from './components/TournamentList/TournamentList';
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import { alpha } from "@mui/material/styles";
import Header from '../tournament/components/Header';
import { TournamentAppProvider } from './TournamentsAppProvider';
import { Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import CustomAlert from '../components/Alerts/CustomAlert';



const Tournament = () => {
    const { theme } = useJumboTheme();
    const location = useLocation();
    const [openAlertSuccess, setOpenAlertSuccess] = React.useState(false);
    const [openAlertSuccessUpdate, setOpenAlertSuccessUpdate] = React.useState(false);

    useEffect(() => {
        if (location.state && location.state.showToast) {
            // Exibir o toast de sucesso aqui
            setOpenAlertSuccess(true);
        }
        if (location.state && location.state.showToastUpdate) {
            // Exibir o toast de sucesso aqui
            setOpenAlertSuccessUpdate(true);
        }

    }, [location]);

    const handleCloseAlertSuccess = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlertSuccess(false);
    };

    const handleCloseAlertSuccessUpdate = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlertSuccessUpdate(false);
    };



    return (
        <TournamentAppProvider>
            <Header />
            <JumboContentLayout
                layoutOptions={{
                    header: {
                        sx: {
                            mt: -4,
                            mb: -7.25,
                            mx: { xs: -4, lg: -6 },
                            p: { xs: theme.spacing(6, 4, 11), lg: theme.spacing(6, 6, 11) },
                            backgroundSize: 'cover',
                            color: 'common.white',
                            position: 'relative',

                            '&::after': {
                                display: 'inline-block',
                                position: 'absolute',
                                content: `''`,
                                inset: 0,

                            }
                        }
                    },
                    sidebar: {
                        sx: {
                            mr: 3.75,
                            width: { xs: '100%', lg: '33%' },
                            [theme.breakpoints.down('lg')]: {
                                minHeight: 0,
                                mr: 0,
                                order: 2
                            }
                        }
                    },
                    wrapper: {
                        sx: {
                            [theme.breakpoints.down('lg')]: {
                                flexDirection: 'column'
                            }
                        }
                    },
                    main: {
                        sx: {
                            [theme.breakpoints.down('lg')]: {
                                minHeight: 0
                            }
                        }
                    }
                }}
            >
                <TournamentList />

                <div>
                    <CustomAlert
                        open={openAlertSuccess}
                        onClose={handleCloseAlertSuccess}
                        severity="success"
                        message="Torneio Criado com sucesso!"
                    />

                    <CustomAlert
                        open={openAlertSuccessUpdate}
                        onClose={handleCloseAlertSuccessUpdate}
                        severity="success"
                        message="Torneio editado com sucesso!"
                    />
                </div>
            </JumboContentLayout>
        </TournamentAppProvider>


    );
};

export default Tournament;