import axios from "./config";

// const token = localStorage.getItem("token");
const userService = {};
userService.getUsers = async (token) => {
  try {


    const response = await axios.get("/users", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });



    return response.data.users;
  } catch (error) {
    console.error("Erro ao buscar torneios:", error);
    throw error; // Você pode lidar com o erro ou lançá-lo para quem chama a função.
  }
};

userService.deleteUser = async (id, token) => {
  try {
    const response = await axios.delete(`/user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });


    return response.data;
  } catch (error) {
    console.error("Erro ao deletar usuário:", error);
    throw error; // Você pode lidar com o erro ou lançá-lo para quem chama a função.
  }
}

userService.getUserById = async (id, token) => {
  try {
    const response = await axios.get(`/user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Erro ao buscar usuário:", error);
    throw error; // Você pode lidar com o erro ou lançá-lo para quem chama a função.
  }
};

userService.updateUser = async (id, data, token) => {
  try {
    const response = await axios.put(`/user/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Erro ao atualizar usuário:", error);
    throw error;
  }
};

userService.createUser = async (data, token) => {
  try {
    const response = await axios.post("/user", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao criar usuário:", error);
    throw error;
  }
};




export { userService };