

import React, { useState } from 'react';
import {
  Button,
  TextField,
  Typography,
  Paper,
  Container,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { tournamentService } from 'app/services/tournament-service';
import CustomAlert from '../components/Alerts/CustomAlert';
import { useNavigate } from 'react-router-dom';
import FormCreateTournament from './components/Forms/FormCreateTournament';
import StageForm from './components/Forms/StageForm';
import QuestionForm from './components/Forms/QuestionForm';
import FormatDate from 'app/components/FormatDate/FormatDate';
import Div from '@jumbo/shared/Div';

function TournamentCreator() {
  const [openAlertSuccess, setOpenAlertSuccess] = React.useState(false);
  const [openAlertError, setOpenAlertError] = React.useState(false);
  const navigate = useNavigate();

  const handleOpenAlertSuccess = () => {
    setOpenAlertSuccess(true)
  };

  const handleOpenAlertError = () => {
    setOpenAlertError(true)
  };

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlertError(false);
  }

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlertSuccess(false);
  };
  const currentDate = new Date(); 

  const [tournamentData, setTournamentData] = useState({
    name: '',
    description: '',
    date_initial: currentDate,
    date_final: currentDate
  });

  const [stages, setStages] = useState([
    {
      order: 1,
      stage_level: '',
      success_message: '',
      failure_message: '',
      questions: [],
    },
  ]);

  const handleTournamentChange = (name, value) => {

    
    setTournamentData({ ...tournamentData, [name]: value });
  };

  const handleStageChange = (event, index) => {
    const { name, value } = event.target;
    const updatedStages = [...stages];
    updatedStages[index][name] = value;
    setStages(updatedStages);
  };

  const handleQuestionChange = (event, stageIndex, questionIndex) => {
    const { name, value } = event.target;
    const updatedStages = [...stages];
    updatedStages[stageIndex].questions[questionIndex][name] = value;
    setStages(updatedStages);
  };

  const addStage = () => {

    const previousStage = stages[stages.length - 1];
    const hasQuestions = previousStage && previousStage.questions.some(question => question.question.trim() !== "");

    if (hasQuestions) {
      setStages([...stages, { order: stages.length + 1, questions: [] }]);
    } else {
      alert("A etapa anterior deve ter pelo menos uma pergunta preenchida.");
    }

  };

  const addQuestion = (stageIndex) => {
    const updatedStages = [...stages];
    updatedStages[stageIndex].questions.push({
      etapa_id: stages[stageIndex].order,
      order: updatedStages[stageIndex].questions.length + 1,
      question: '',
      points: 10,
      alternatives: [
        { pergunta_order: 1, alternative: 'A', description: '', is_correct: false },
        { pergunta_order: 1, alternative: 'B', description: '', is_correct: false },
        { pergunta_order: 1, alternative: 'C', description: '', is_correct: false },
        { pergunta_order: 1, alternative: 'D', description: '', is_correct: false },
      ],
    });
    setStages(updatedStages);
  };

  const handleAlternativeChange = (event, stageIndex, questionIndex, altIndex) => {
    const { name, value } = event.target;
    const updatedStages = [...stages];
    updatedStages[stageIndex].questions[questionIndex].alternatives[altIndex][name] = value;
    setStages(updatedStages);
  };

  const handleRadioChange = (event, stageIndex, questionIndex, altIndex) => {
    const { value } = event.target;
    const updatedStages = [...stages];
    updatedStages[stageIndex].questions[questionIndex].alternatives.forEach(
      (alternative, index) => {
        alternative.is_correct = index === altIndex; 
      }
    );
    setStages(updatedStages);
  };

  const handleRemoveStage = (stageIndex) => {
    const updatedStages = [...stages];
    updatedStages.splice(stageIndex, 1); // Remove a etapa
    setStages(updatedStages);
  };

  const handleRemoveQuestion = (stageIndex, questionIndex) => {
    const updatedStages = [...stages];
    updatedStages[stageIndex].questions.splice(questionIndex, 1); 
    setStages(updatedStages);
  };


  const handleJSONExport = () => {
    const jsonData = {
      tournaments: {
        ...tournamentData,
      },
      stages: stages,
    };
    



  };





  const handleSubmitTournament = async () => {

    const formattedTournamentData = {
      ...tournamentData,
      date_initial: FormatDate(tournamentData.date_initial),
      date_final: FormatDate(tournamentData.date_final),
    };

    const jsonData = {
      tournaments: formattedTournamentData,
      stages: stages,
    };


    
    const token = localStorage.getItem("token");
    try {
      await tournamentService.createTournament(jsonData, token)

      const response = await tournamentService.getTournaments(token);

     


      navigate("/tournaments", { state: { showToast: true } });
      handleOpenAlertSuccess();
      


    }
    catch (error) {
      handleOpenAlertError();
      console.error("Erro ao criar torneios:", error);
      throw error; // Você pode lidar com o erro ou lançá-lo para quem chama a função.
    }

  }

  return (
    <Div>

      <FormCreateTournament
        tournamentData={tournamentData}
        stages={stages}
        handleTournamentChange={handleTournamentChange}
        handleStageChange={handleStageChange}
        addStage={addStage}
        addQuestion={addQuestion}
        handleQuestionChange={handleQuestionChange}
        handleSubmitTournament={handleSubmitTournament}
      />
      {stages.map((stage, stageIndex) => (
        <Paper key={stageIndex} style={{ padding: '16px', margin: '16px 0' }}>
          <StageForm
            key={stageIndex}
            stage={stage}
            stageIndex={stageIndex}
            handleStageChange={handleStageChange}
            addQuestion={addQuestion}
            handleQuestionChange={handleQuestionChange}
          />
          <Button onClick={() => handleRemoveStage(stageIndex)}>Excluir Etapa</Button>


          {stage.questions.map((question, questionIndex) => (
            <div key={questionIndex}>
              <QuestionForm
                key={questionIndex}
                question={question}
                stageIndex={stageIndex}
                questionIndex={questionIndex}
                handleQuestionChange={handleQuestionChange}

                handleAlternativeChange={handleAlternativeChange}
                handleRadioChange={handleRadioChange}
              />

              <Button onClick={() => handleRemoveQuestion(stageIndex, questionIndex)}>Excluir Pergunta</Button>



            </div>
          ))}
        </Paper>
      ))}
      <Button onClick={addStage}>Adicionar Etapa</Button>
      <Button onClick={handleSubmitTournament}>Salvar Torneio</Button>

      <CustomAlert
        open={openAlertSuccess}
        onClose={handleCloseAlertSuccess}
        severity="success" // Pode ser 'error', 'warning', 'info', ou 'success'
        message="Torneio Criado com sucesso!"
      />

      <CustomAlert
        open={openAlertError}
        onClose={handleCloseAlertError}
        severity="error" // Pode ser 'error', 'warning', 'info', ou 'success'
        message="Erro ao criar torneio!"
      />
    </Div>
  );
}

export default TournamentCreator;
