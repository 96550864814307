import { tournamentService } from 'app/services/tournament-service';
import React, { useContext, useState } from 'react';
import TournamentsAppContext from './TournamentsAppContext';



export function TournamentAppProvider({ children }) {
  const [tournaments, setTournaments] = useState([]);

  const getTournaments = (token) => {
    tournamentService.getTournaments(token)
      .then((response) => {
        setTournaments(response);

        return response;
      })
      .catch((error) => {
        console.error(error);
      });
  }



  return (
    <TournamentsAppContext.Provider value={{ tournaments, setTournaments, getTournaments }}>
      {children}
    </TournamentsAppContext.Provider>
  );
}

export function useTournament() {

  return useContext(TournamentsAppContext);
}
