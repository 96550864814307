import React from 'react';
import Grid from "@mui/material/Grid";
import Contacts from "./components/Contacts";



const UserProfileSidebar = ({User}) => {
    
    return (
        <Grid container spacing={3.75}>
            <Grid item xs={12} md={6} lg={12}>
                <Contacts User={User} />
            </Grid>

        </Grid>
    );
};

export default UserProfileSidebar;
