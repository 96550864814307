import React from 'react';
import PropTypes from 'prop-types';

import {
  TextField,
  Typography,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
  Box,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import InputMask from 'react-input-mask';
import { NumericFormat } from 'react-number-format';



const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalScale={3}
      allowNegative={false}

    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};


function QuestionForm(props) {
  const { question, stageIndex, questionIndex, handleQuestionChange, addAlternative, handleAlternativeChange, handleRadioChange, } = props;


  return (
    <Div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '24ch' },
        }}
        noValidate
        autoComplete="off" >
        <Div sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle1">Pergunta {question.order}</Typography>
          <Grid container spacing={3}>
            <Grid item xs={9}>
              <TextField
                name="question"
                label="Texto da Pergunta"
                value={question.question}
                onChange={(e) => handleQuestionChange(e, stageIndex, questionIndex)}
                style={{ width: '100%' }} 
              />
            </Grid>
            <Grid item xs={3}>

              <TextField

                value={question.points}
                onChange={(e) => handleQuestionChange(e, stageIndex, questionIndex)}
                name="points"
                label="Pontos"

                InputProps={{
                  inputComponent: NumericFormatCustom,
                }}
                style={{ width: '100%' }} 

              />
            </Grid>

          </Grid>

          <Div sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 1, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              {question.alternatives.map((alternative, altIndex) => (
                <Grid item xs={2} sm={4} md={3} key={altIndex}>
                  <Typography variant="subtitle1">Alternativa {alternative.alternative}</Typography>
                  <TextField
                    name="description"
                    label={`Descrição da Alternativa ${alternative.alternative}`}
                    value={alternative.description}
                    onChange={(e) =>
                      handleAlternativeChange(e, stageIndex, questionIndex, altIndex)
                    }
                    style={{ width: '100%' }}                      
                  />
                  <RadioGroup
                    name={`is_correct_${stageIndex}_${questionIndex}_${altIndex}`}
                    value={alternative.is_correct} // Converta o valor booleano para string
                    onChange={(e) =>
                      handleRadioChange(e, stageIndex, questionIndex, altIndex)}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Correta"
                    />
                    {/* <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="Incorreta"
                    /> */}
                  </RadioGroup>
                </Grid>
              ))}
            </Grid>
          </Div>

        </Div>

      </Box >

    </Div >

  );
}

export default QuestionForm;
