import React from 'react';
import {
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from '@mui/material';

function QuestionFormEdit({
  question,
  alternatives,
  questionIndex,
  categories,
  levels,
  handleQuestionChange,
  handleAlternativeChange,
  handleRadioChange,
  handleUpdateQuestion,
}) {

  return (
    <Paper style={{ padding: '16px', margin: '16px 0' }}>
      <TextField
        label="Pergunta"
        name="question"
        value={question.question}
        onChange={(e) => handleQuestionChange(e, questionIndex)}
        fullWidth
        required
        style={{ marginBottom: '16px' }}

      />

      <Grid container spacing={2}>
        <Grid item xs={6}> {/* Define que a categoria ocupará 50% da largura */}
          <FormControl fullWidth>
            <InputLabel>Categoria</InputLabel>
            <Select
              name="category_id"
              value={question.category_id}
              onChange={(e) => handleQuestionChange(e, questionIndex)}
              style={{ marginBottom: '16px' }}
            >
              {categories.map((category, index) => (
                <MenuItem key={index} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}> {/* Define que o nível ocupará 50% da largura */}
          <FormControl fullWidth>
            <InputLabel>Nível</InputLabel>
            <Select
              name="level_id"
              value={question.level_id}
              //onChange={(e) => handleQuestionChange(e, questionIndex)}
              style={{ marginBottom: '16px' }}
            >
              {levels.map((level, index) => (
                <MenuItem key={index} value={level.id}>
                  {level.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>



      <Grid container spacing={2}>
        {alternatives.map((alternative, altIndex) => (
          <Grid item xs={6} key={altIndex}>
            <TextField
              label={`Alternativa ${alternative.alternative}`}
              name="description"
              value={alternative.description}
              onChange={(e) => handleAlternativeChange(e, altIndex)}
              fullWidth

            />

            <RadioGroup
              name="is_correct"
              value={alternative.is_correct ? "true" : "false"}
              onChange={(e) => handleRadioChange(e, altIndex)}
            >
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Correta"
              />

            </RadioGroup>
          </Grid>
        ))}
      </Grid>



      <Button onClick={() => handleUpdateQuestion(question.id)}>
        Atualizar Pergunta
      </Button>
    </Paper>
  );
}

export default QuestionFormEdit;
