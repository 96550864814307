import React from 'react';

import { Button } from "@mui/material";
import ContentHeader from "../../../layouts/shared/headers/ContentHeader";

import MenuItem from "@mui/material/MenuItem";

import styled from "@emotion/styled";

import Stack from "@mui/material/Stack";

import AddIcon from '@mui/icons-material/Add';
import { useJumboTheme } from '@jumbo/hooks';
import { tournamentService } from 'app/services/tournament-service';
import { useTournament } from '../../tournament/TournamentsAppProvider';
import { useNavigate } from 'react-router-dom';



const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(0, 1),

  '&:hover': {
    backgroundColor: 'transparent',
  },

  '& .MuiTouchRipple-root': {
    display: 'none'
  }

}));



const Item = styled("div")({
  textAlign: 'center',
});


const Header = () => {
  

  return (
    <ContentHeader
      title={"Rankings"}
      children={
        <Stack
          direction={"row"}
          justifyContent={"space-evenly"}
          spacing={2}
          sx={{
            mx: 1
          }}
        >
         
        

          


        </Stack>
      }
    />

  );
};

export default Header;
