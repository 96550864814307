import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import { useJumboTheme } from '@jumbo/hooks';
import ContentHeader from 'app/layouts/shared/headers/ContentHeader';
import { tournamentService } from 'app/services/tournament-service';
import React, { useEffect, useState } from 'react'
import TournamentEditor from './TournamentEditor';
import { TournamentAppProvider, useTournament } from './TournamentsAppProvider';


const TournamentEdit = () => {
  const { theme } = useJumboTheme();
  const { tournaments, setTournaments, getTournaments } = useTournament();
  const token = localStorage.getItem("token");

  const [expandedQuestions, setExpandedQuestions] = useState([]);
  const [tournamentData, setTournamentData] = useState(null); // Inicialize com null

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tournamentId = window.location.pathname.split('/').pop();
        const res = await tournamentService.getTournament(tournamentId, token)

        if (!res) {
          return;
        }

        if (res) {
          setTournamentData(res);

        } else {
          console.error("No tournaments in the list.");
        }
      } catch (error) {
        console.error("Error fetching tournaments:", error);
      }
    };

    fetchData();
  }, []);


  const toggleQuestion = (questionId) => {
    // Verificar se a pergunta está na lista de expandidas
    const isExpanded = expandedQuestions.includes(questionId);

    // Se estiver expandida, remova-a; caso contrário, adicione-a à lista
    if (isExpanded) {
      setExpandedQuestions(expandedQuestions.filter((id) => id !== questionId));
    } else {
      setExpandedQuestions([...expandedQuestions, questionId]);
    }
  };





  console.error("tournamentData", tournamentData)
  if (!tournamentData) {
    return <p>Carregando torneio...</p>; // Página de carregamento enquanto os dados são buscados
  }

  return (

    <TournamentAppProvider>
      <ContentHeader
        title={"Editar Torneio"}

      />
      <JumboContentLayout
        layoutOptions={{
          header: {
            sx: {
              mt: -4,
              mb: -7.25,
              mx: { xs: -4, lg: -6 },
              p: { xs: theme.spacing(6, 4, 11), lg: theme.spacing(6, 6, 11) },
              backgroundSize: 'cover',
              color: 'common.white',
              position: 'relative',

              '&::after': {
                display: 'inline-block',
                position: 'absolute',
                content: `''`,
                inset: 0,

              }
            }
          },
          sidebar: {
            sx: {
              mr: 3.75,
              width: { xs: '100%', lg: '33%' },
              [theme.breakpoints.down('lg')]: {
                minHeight: 0,
                mr: 0,
                order: 2
              }
            }
          },
          wrapper: {
            sx: {
              [theme.breakpoints.down('lg')]: {
                flexDirection: 'column'
              }
            }
          },
          main: {
            sx: {
              [theme.breakpoints.down('lg')]: {
                minHeight: 0
              }
            }
          }
        }}
      >
        {tournamentData && (
          <TournamentEditor tournamentDataOrigin={tournamentData} />
        )}

      </JumboContentLayout>
    </TournamentAppProvider>


  );



};

export default TournamentEdit;