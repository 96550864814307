import axios from "./config";


const questionService = {};
questionService.getQuestions = async (token) => {
  try {
    const response = await axios.get("/questions", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });



    return response.data;
  } catch (error) {
    console.error("Erro ao buscar torneios:", error);
    throw error; // Você pode lidar com o erro ou lançá-lo para quem chama a função.
  }
};

questionService.getCategories = async (token) => {
  try {
    const response = await axios.get("/categories", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Erro ao buscar categorias:", error);
    throw error; // Você pode lidar com o erro ou lançá-lo para quem chama a função.
  }
}

questionService.getLevels = async (token) => {
  try {
    const response = await axios.get("/levels", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Erro ao buscar categorias:", error);
    throw error; // Você pode lidar com o erro ou lançá-lo para quem chama a função.
  }
}

questionService.createQuestions = async (token, question) => {
  try {
    const response = await axios.post("/questions", question, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Erro ao buscar categorias:", error);
    throw error; // Você pode lidar com o erro ou lançá-lo para quem chama a função.
  }
}

questionService.deleteQuestion = async (token, questionId) => {
  try {
    const response = await axios.delete(`/questions/${questionId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Erro ao buscar categorias:", error);
    throw error; // Você pode lidar com o erro ou lançá-lo para quem chama a função.
  }
}

questionService.updateQuestion = async (token, questionId, questionData) => {
  try {
    const response = await axios.put(`/questions/${questionId}`,questionData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Erro ao buscar categorias:", error);
    throw error; // Você pode lidar com o erro ou lançá-lo para quem chama a função.
  }
}

questionService.getQuestion = async (token, questionId) => {
  try {
    const response = await axios.get(`/questions/${questionId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Erro ao buscar categorias:", error);
    throw error; // Você pode lidar com o erro ou lançá-lo para quem chama a função.
  }
}


export { questionService };