import React from 'react';
import {
  TextField,
  Typography,
  Paper,
  Button,
  Grid,
  Box,
} from '@mui/material';
import Div from '@jumbo/shared/Div';

function StageForm(props) {
  const { stage, stageIndex, handleStageChange, addQuestion, handleQuestionChange } = props;

  return (

    <Div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '24ch' },
        }}
        noValidate
        autoComplete="off" >
        <Div sx={{ flexGrow: 1 }}>
          <Typography variant="h5">Etapa {stage.order}</Typography>
          <Grid container spacing={4}>
            <Grid item xs>
              <TextField
                name="stage_level"
                label="Nível da Etapa"
                value={stage.stage_level}
                onChange={(e) => handleStageChange(e, stageIndex)}
                style={{ width: '100%' }} 
              />
            </Grid>



            <Grid item xs>
              <TextField
                name="success_message"
                label="Mensagem de Sucesso"
                value={stage.success_message}
                onChange={(e) => handleStageChange(e, stageIndex)}
                style={{ width: '100%' }} 
              />
            </Grid>



            <Grid item xs>
              <TextField
                name="failure_message"
                label="Mensagem de Falha"
                value={stage.failure_message}
                onChange={(e) => handleStageChange(e, stageIndex)}
                style={{ width: '100%' }} 
              />
            </Grid>



            <Grid item xs>
              <Button onClick={() => addQuestion(stageIndex)}>Adicionar Pergunta</Button>
            </Grid>
          </Grid>

        </Div>
      </Box>

    </Div>






  );
}

export default StageForm;
